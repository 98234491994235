.fancyContainer {
  display: flex;
  gap: 16px;

  p {
    color: var(--primary-main);
  }

  img {
    margin-top: 4px;
  }
}
